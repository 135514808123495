.pagination {
	display: flex;
	justify-content: center;
	padding: 1rem 0;
	list-style: none;
}

.pagination__link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.5rem;
	.btn-outline-theme {
		color: var(--#{$prefix}theme);
		border-color: var(--#{$prefix}theme);

		&:hover,
		&.active {
			background-color: var(--#{$prefix}theme);
			border-color: var(--#{$prefix}theme);
		}
		&.disabled,
		&:disabled {
			color: var(--#{$prefix}theme);
		}
		&:focus,
		&:active,
		&.active:focus,
		&:active:focus {
			@include box-shadow(0 0 0 0.25rem rgba(#{var(--#{$prefix}theme-rgb)}, 0.5));
		}
	}
	height: 2.5rem;
	margin: 0 0.25rem;
	border: 1px solid #ddd;
	border-radius: 50%;
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
}

.pagination__link--disabled {
	color: #ccc;
	cursor: not-allowed;
}

.pagination__link--active {
	background-color: #007bff;
	color: white;
	border-color: #007bff;
}

.pagination__page {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 0.25rem;
	border: 1px solid #ddd;
	border-radius: 50%;
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
}

.pagination__break {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 0.25rem;
	border: 1px solid #ddd;
	border-radius: 50%;
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
}
